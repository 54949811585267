import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import AppContext from "team-portal/contexts/app-context";
import * as vals from "team-portal/utils/validators";
import { ClickAwayListener } from "@material-ui/core";
import { Button, Formblock, Label } from "ui";
import { MiterAPI, TeamPortalUser } from "team-portal/utils/miter";
import Notifier from "team-portal/utils/notifier";
import {
  eeoEthnicityCategories,
  eeoGenderCategories,
  eeoVeteranStatusCategories,
  eeoMaritalStatusCategories,
  eeoDisabilityStatusCategories,
  formatToAddress,
} from "miter-utils";
import { isEmptyAddress, isValidAddress } from "miter-utils";
import { DateTime } from "luxon";
import { states } from "miter-utils/lists";
import { t } from "i18next";
import { Address, UpdateTeamMemberParams } from "dashboard/miter";
import { ChangeRequest } from "../../../../backend/models";
import styles from "../teamPortalComponents.module.css";
import { useTranslation } from "react-i18next";

type Props = {
  hide: () => void;
};

const EditProfileModal: React.FC<Props> = ({ hide }) => {
  const { t } = useTranslation();
  const { activeTM, device, fetchUserData } = React.useContext(AppContext);

  const isEmployee = activeTM!.employment_type === "employee";
  const payrollTeamMember = !!activeTM?.check_tm;

  const tmDemographics = activeTM?.demographics;
  const teamSettings = activeTM?.company.settings.team;

  const enabledDemographicQuestions = useMemo(
    () => teamSettings?.enabled_demographic_questions || {},
    [teamSettings]
  );

  const defaultValues = buildDefaultValues(activeTM);
  const form = useForm({
    reValidateMode: "onChange",
    mode: "all",
    defaultValues: defaultValues,
  });

  const { register, control, handleSubmit, errors, watch } = form;

  const formData = watch();

  const deptPolicyId = activeTM?.department_id;
  const companyPolicyId = activeTM?.company?.settings?.team?.default_change_request_policy_id;
  const enabledChangeRequestPolicies = activeTM?.company?.settings?.team?.enable_change_request_policies;
  const hasChangeRequestPolicy = enabledChangeRequestPolicies && !!(deptPolicyId || companyPolicyId);

  // State
  const [saving, setSaving] = useState(false);
  const [suggestedAltAddress, setSuggestedAltAddress] = useState<Address>();
  const [oldFormData, setOldFormData] = useState<$TSFixMe>();

  const save = async (formData) => {
    setSaving(true);
    formData = suggestedAltAddress ? { ...oldFormData, address: suggestedAltAddress } : formData;

    let cleanedData: UpdateTeamMemberParams;
    try {
      cleanedData = cleanFormData(formData, activeTM!);
    } catch (err: $TSFixMe) {
      Notifier.error(err.message);
      setSaving(false);
      return;
    }

    if (hasChangeRequestPolicy) {
      const fieldsChanged = Object.keys(cleanedData)
        .filter(
          (key) => (!!cleanedData[key] || cleanedData[key] === null) && cleanedData[key] !== activeTM[key]
        )
        .map((key) => {
          return {
            key: key,
            value: {
              previous_value: key in activeTM ? activeTM[key] : null,
              new_value: cleanedData[key],
            },
          };
        });
      if (!cleanedData || fieldsChanged.length === 0) {
        Notifier.error(t("No changes detected."));
        setSaving(false);
        return;
      }
      const changeRequestData: Partial<ChangeRequest> = {
        parent_type: "team_member",
        parent_id: activeTM._id,
        company_id: activeTM.company?._id,
        author_id: activeTM?.user,
        author_team_member_id: activeTM._id,
        department_id: activeTM?.department_id,
        fields_changed: fieldsChanged,
        child_fields: { category: "all" },
      };

      try {
        const response = await MiterAPI.change_requests.create(changeRequestData);
        if (response.error) throw new Error(response.error);
        Notifier.success(t("Change request created successfully"));
        hide();
      } catch (e: $TSFixMe) {
        Notifier.error(e.message);
      }
    } else {
      try {
        const response = await MiterAPI.team_member.update(activeTM!._id.toString(), cleanedData);
        setSuggestedAltAddress(response.suggested_alternative);
        if (response.error) {
          if (response.suggested_alternative) {
            setOldFormData(oldFormData || formData);
          } else {
            throw new Error(response.error);
          }
        } else {
          await fetchUserData(undefined);
          Notifier.success(t("Personal info successfully updated."));
          hide();
        }
      } catch (e: $TSFixMe) {
        console.log("Error saving personal info", e);
        Notifier.error(`${t("There was an error saving your information.")} ${e.message}`);
        setOldFormData(undefined);
      }
    }

    setSaving(false);
  };

  const ssnRequired =
    !!activeTM?.check_tm?.onboard?.remaining_steps?.includes("ssn") && !activeTM?.ssn_last_four;
  let saveButtonText = t("Save");
  if (hasChangeRequestPolicy) {
    saveButtonText = t("Request changes");
  } else if (suggestedAltAddress) {
    saveButtonText = t("Confirm");
  }

  return (
    <div className="modal-background">
      <ClickAwayListener onClickAway={() => hide()}>
        <div className={"modal-wrapper form " + device}>
          <div className="modal-header form">{t("Edit profile")}</div>
          {hasChangeRequestPolicy && (
            <div className={styles["modal-banner"]}>
              {t(
                "Your administrator requires approvals for changes to profile info. Your changes will be approved upon review."
              )}
            </div>
          )}
          {suggestedAltAddress && (
            <div className="modal-body form">
              <div className="vertical-spacer"></div>
              {t(
                "We could not find that exact address in our database, but here is one that looks similar. Please confirm this is your address:"
              )}
              <div className="vertical-spacer"></div>
              <Formblock
                type="address"
                name="address"
                register={register}
                defaultValue={suggestedAltAddress}
                className="modal wizard"
                editing={false}
              />
            </div>
          )}
          {!suggestedAltAddress && (
            <div className="modal-body form">
              <div className="vertical-spacer"></div>
              <Formblock
                label={t("Personal email")}
                defaultValue={activeTM!.email}
                type="text"
                name="email"
                className="modal"
                errors={errors}
                register={register(vals.email)}
                editing={true}
              />
              <Formblock
                label={t("Phone number")}
                defaultValue={activeTM!.phone}
                type="phone"
                name="phone"
                className="modal"
                errors={errors}
                control={control}
                register={register(vals.phoneNumber)}
                editing={true}
              />
              {isEmployee && (
                <Formblock
                  label={`${t("Date of birth")}${payrollTeamMember ? "*" : ""}`}
                  type="datetime"
                  dateOnly={true}
                  name="dob"
                  placeholder="MM/DD/YYYY"
                  form={form}
                  className="modal wizard"
                  editing={true}
                  rules={payrollTeamMember ? vals.required : undefined}
                />
              )}
              <Formblock
                label={`${t("Address")}${payrollTeamMember ? "*" : ""}`}
                type="address"
                name="address"
                form={form}
                className="modal wizard"
                editing={true}
                val={payrollTeamMember ? vals.required : undefined}
                rules={payrollTeamMember ? vals.required : undefined}
              />

              <Formblock
                form={form}
                name="use_same_address_for_mailing_address"
                type="checkbox"
                text={t("Use same address for mailing address")}
                editing={true}
                register={register}
                className="modal"
                defaultValue={!!defaultValues.use_same_address_for_mailing_address}
              />

              {!formData.use_same_address_for_mailing_address && (
                <Formblock
                  label={t("Mailing address")}
                  type="address"
                  name="mailing_address"
                  form={form}
                  className="modal wizard"
                  editing={true}
                />
              )}
              <Label className="modal" label={t("SSN*")} labelInfo={t("SSN is required to run payroll")} />

              <Formblock
                label=""
                type="ssn"
                name="ssn"
                val={ssnRequired ? vals.required : undefined}
                control={control}
                className="modal"
                errors={errors}
                editing={true}
                defaultValue={undefined}
                placeholder={
                  "XXX-XX-" + (activeTM?.check_tm?.ssn_last_four || activeTM?.ssn_last_four || "XXXX")
                }
              />
              {isEmployee && teamSettings?.enable_demographic_reporting && (
                <>
                  {enabledDemographicQuestions["ethnicity"] && (
                    <Formblock
                      label={t("Race/ethnicity")}
                      defaultValue={tmDemographics?.ethnicity}
                      type="select"
                      name="ethnicity"
                      options={eeoEthnicityCategories.map((c) => ({ label: c, value: c }))}
                      control={control}
                      className="modal small-margin"
                      errors={errors}
                      editing={true}
                    />
                  )}
                  {enabledDemographicQuestions["gender"] && (
                    <Formblock
                      label={t("Gender")}
                      defaultValue={tmDemographics?.gender}
                      type="select"
                      name="gender"
                      options={eeoGenderCategories.map((c) => ({ label: c, value: c }))}
                      control={control}
                      className="modal small-margin"
                      errors={errors}
                      editing={true}
                    />
                  )}
                  {enabledDemographicQuestions["veteran_status"] && (
                    <Formblock
                      label={t("Veteran status")}
                      defaultValue={tmDemographics?.veteran_status}
                      type="select"
                      name="veteran_status"
                      options={eeoVeteranStatusCategories.map((c) => ({ label: c, value: c }))}
                      control={control}
                      className="modal small-margin"
                      errors={errors}
                      editing={true}
                    />
                  )}
                  {enabledDemographicQuestions["marital_status"] && (
                    <Formblock
                      label={t("Marital status")}
                      defaultValue={tmDemographics?.marital_status}
                      type="select"
                      name="marital_status"
                      options={eeoMaritalStatusCategories.map((c) => ({ label: c, value: c }))}
                      control={control}
                      className="modal small-margin"
                      errors={errors}
                      editing={true}
                    />
                  )}
                  {enabledDemographicQuestions["disability_status"] && (
                    <Formblock
                      label={t("Disability status")}
                      defaultValue={tmDemographics?.disability_status}
                      type="select"
                      name="disability_status"
                      options={eeoDisabilityStatusCategories.map((c) => ({ label: c, value: c }))}
                      control={control}
                      className="modal small-margin"
                      errors={errors}
                      editing={true}
                    />
                  )}
                </>
              )}

              <Formblock
                type="checkbox"
                name="accept_sms"
                editing={true}
                defaultValue={activeTM?.accept_sms}
                text={t(
                  "By checking this box, I agree to receive SMS's from Miter. You can text STOP to unsubscribe at any time."
                )}
                style={{ marginTop: 20, marginBottom: 20 }}
                form={form}
                checkboxWrapperStyle={{ alignItems: "flex-start" }}
              />
              <div className="vertical-spacer"></div>
            </div>
          )}
          <div className="modal-footer form">
            <div className="flex-1"></div>
            <button onClick={hide} className="button-1">
              {t("Cancel")}
            </button>
            <Button
              onClick={handleSubmit(save)}
              loading={saving}
              className={saving ? "button-2 clicked" : "button-2"}
            >
              {t(saveButtonText)}
            </Button>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default EditProfileModal;

const cleanFormData = (formData, tm: TeamPortalUser): UpdateTeamMemberParams => {
  const emailAndPhoneEmpty = !formData.email && !formData.phone;
  if (emailAndPhoneEmpty) {
    throw new Error(t("Please enter an email or phone number."));
  }

  const cleanAddress = formatToAddress(formData.address);
  const addressEmpty = isEmptyAddress(cleanAddress);

  // If the address is not empty and is not valid, throw an error
  if (!addressEmpty && !isValidAddress(cleanAddress)) {
    throw new Error(t("Address is not valid. Please make sure all the required fields are filled out."));
  }

  if (tm.check_tm && addressEmpty) {
    throw new Error(t("Please enter an address."));
  }

  const cleanMailingAddress = formData?.use_same_address_for_mailing_address
    ? null
    : formatToAddress(formData.mailing_address);

  const mailingAddressEmpty = isEmptyAddress(cleanMailingAddress);

  // If the mailing_address is not empty and is not valid, throw an error
  if (!mailingAddressEmpty && !isValidAddress(cleanMailingAddress)) {
    throw new Error(
      t("Mailing address is not valid. Please make sure all the required fields are filled out.")
    );
  }

  if (formData.ssn && (isNaN(Number(formData.ssn)) || formData.ssn.length !== 9)) {
    throw new Error(t("Please enter a valid SSN."));
  }

  const newData: UpdateTeamMemberParams = { ...formData };
  const fieldsToExclude = [
    "gender",
    "ethnicity",
    "veteran_status",
    "marital_status",
    "disability_status",
    "use_same_address_for_mailing_address",
  ];
  for (const field of fieldsToExclude) {
    delete newData[field];
  }

  const calculateMailingAddress = () => {
    if (cleanMailingAddress === null) return null;
    if (!mailingAddressEmpty) return cleanMailingAddress;
    return undefined;
  };

  newData.address = !addressEmpty ? cleanAddress : undefined;
  newData.mailing_address = calculateMailingAddress();

  if (tm.company.settings.team?.enable_demographic_reporting) {
    if (
      formData?.gender?.value !== tm.demographics?.gender ||
      formData?.ethnicity?.value !== tm.demographics?.ethnicity ||
      formData?.veteran_status?.value !== tm.demographics?.veteran_status ||
      formData?.marital_status?.value !== tm.demographics?.marital_status ||
      formData?.disability_status?.value !== tm.demographics?.disability_status
    ) {
      newData.demographics = {
        ...tm.demographics,
        ethnicity: formData?.ethnicity?.value,
        gender: formData?.gender?.value,
        veteran_status: formData?.veteran_status?.value,
        marital_status: formData?.marital_status?.value,
        disability_status: formData?.disability_status?.value,
      };
    }
  }

  if (formData.dob) {
    // Clean dob
    newData.dob = formData.dob.toISODate();
  }

  return newData;
};

const buildDefaultValues = (teamMember: TeamPortalUser | undefined): Record<string, unknown> => {
  if (!teamMember) return {};

  return {
    dob: teamMember.dob ? DateTime.fromISO(teamMember.dob) : undefined,
    address: teamMember.address?.state
      ? {
          ...teamMember.address,
          state: {
            label: states.find((s) => s.abbreviation === teamMember.address?.state)?.abbreviation,
            value: teamMember.address?.state,
          },
        }
      : undefined,
    mailing_address: teamMember.mailing_address?.state
      ? {
          ...teamMember.mailing_address,
          state: {
            label: states.find((s) => s.abbreviation === teamMember.mailing_address?.state)?.abbreviation,
            value: teamMember.mailing_address?.state,
          },
        }
      : undefined,
    use_same_address_for_mailing_address: teamMember.mailing_address
      ? isEmptyAddress(formatToAddress(teamMember.mailing_address))
      : false,
    accept_sms: teamMember.accept_sms,
  };
};
