import { TranslationMap } from "./i18n";

const translation: TranslationMap = {
  "Select a payment method": {
    en: "Select a payment method",
    es: "Seleccione un método de pago",
  },
};

export default translation;
